
.divSidebar{
    background-color: brown;
    width: auto;
}

.h1{
  font-size: 14px;
  margin: -1px;
  color: "black";
  
}

.h2{
  font-size: 14px;
  font-weight: 400;
  text-transform:uppercase;
  color: "black";
}

.h3{
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
}

.avatar {
  margin-top: 0px;
  border-radius: 50%;
  height: 60px;
  object-fit: cover;
  object-position: center;
  width: 60px;
}

.paperDatos{
  margin-top: 40px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -30px;
   align-items: left;
   text-align: left;
}

.btnlogout{
  align-items: center;
   text-align: center;
}
