/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
   
    background: #BD00FF;
    border-radius: 40px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8A00BB;
    border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6E0095;
}